import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 查看物流/api/getNavigationList

export function getSearchExpressList2(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/order/searchExpressList2`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
export function getLogisticsProduct(data,langEnv=2){
  return request({
      url:`${baseURL.api}/authapi/order/searchExpressGoodsList`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}
export function getLogisticsByOrderNo(data,langEnv=2){
  return request({
      url:`${baseURL.javaApi}/authapi/rp/find-logistics`,
      method: "post",
      data,
      headers:{
        appLangEnv:langEnv
      }
  });
}